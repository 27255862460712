import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzModalWrapperComponent } from './kurz-modal-wrapper.component';
import { UtilModalModule } from '@util/components/util-modal/util-modal.module';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@util/components/icon/icon.module';



@NgModule({
  declarations: [
    KurzModalWrapperComponent
  ],
  imports: [
    CommonModule,
    UtilModalModule,
    IconButtonModule,
    IconModule,
    ActivateElementModule,
    I18nModule,
  ],
  exports: [
    KurzModalWrapperComponent,
    UtilModalModule
  ]
})
export class KurzModalWrapperModule { }
